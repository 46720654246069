<script setup lang="ts">
import $api from '../../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import { computed, onMounted, ref, watch } from 'vue'
import _ from 'lodash'
import qs from 'qs'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { useRoute } from 'vue-router'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import AmountText from '@/components/AmountText.vue'

const props = defineProps({
  shpAgentId: {
    type: String,
    default() {
      return ''
    }
  }
})
const emit = defineEmits(['itemClick'])

const route = useRoute()
let pagerKey = 'pager' + '_' + route.fullPath
let pagerJson = sessionStorage.getItem(pagerKey)
const pager = ref(pagerJson ? JSON.parse(pagerJson) : {
  pageIndex: 1,
  pageSize: 10,
  itemCount: 0
})

watch(pager, () => {
  sessionStorage.setItem(pagerKey, JSON.stringify(pager.value))
}, { deep: true })

const keywords = ref("");
const items = ref([])
const selection = ref([])
const LIST_URL = $api.shpProduct.list
const DELETE_URL = $api.shpProduct.delete
const BATCH_DELETE_URL = $api.shpProduct.batchDelete
const searchQueryCond = {
  name: 'LIKE'
}

let searchQueryKey = 'searchQuery' + '_' + route.fullPath
let searchQueryJson = sessionStorage.getItem(searchQueryKey)

// 检索参数开始
const searchQuery = {
  name: '',
  status: ''
}

const sqy = ref(searchQueryJson ? JSON.parse(searchQueryJson) : Object.assign(searchQuery))
watch(sqy, () => {
  sessionStorage.setItem(searchQueryKey, JSON.stringify(sqy.value))
}, { deep: true })

watch(keywords,()=>{
   handleLoadData();
})

const dynamicQuery = computed(() => {
  let p = {}
  for (let key in sqy.value) {
    if (sqy.value[key]) {
      if (searchQueryCond[key]) {
        p[`${key}@${searchQueryCond[key]}`] = sqy.value[key]
      } else {
        p[`${key}`] = sqy.value[key]
      }
    }
  }
  return p
})

watch(() => props.shpAgentId, () => {
  items.value = []
  handleLoadData()
})

async function handleLoadData() {
  let resp = await $http.post(LIST_URL, qs.stringify(Object.assign({ shpAgentId: props.shpAgentId,keywords: keywords.value}, pager.value, { conds: DcUtils.param2DynamicCondQuery(dynamicQuery.value) })))
  items.value = resp.data.items
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
}

async function handleClick() {

}

onMounted(() => {
  handleLoadData()
})

</script>

<template>
  <div class="flex-1 flex flex-col">
    <div class="flex flex-row-reverse  h-[56px] items-center border-b pl-[12px] pr-[12px]">
      <div class="w-[238px] ">
        <el-input size="default" v-model="keywords" placeholder="关键字检索"></el-input>
      </div>
    </div>
    <el-scrollbar v-if="items.length" class="flex-1 bg-[#F0F2F5] ">
      <div class="flex flex-wrap p-[6px] ">
        <template v-for="(item,i) in items" :key="i">
          <div class="w-1/5 p-[6px]" @click="emit('itemClick',item)">
            <div class="border-[1px]  bg-[#fff] cursor-pointer">
              <div class="p-[12px]">
                <el-text size="default" line-clamp="1">{{ item.name }}</el-text>
              </div>
              <div class="flex justify-center">
                <DcSinglePicShow class="border" width="128px" height="128px"
                                 :model-value="item.pics ? item.pics.split(',')[0] : ''"></DcSinglePicShow>
              </div>
              <div class="p-[12px]  flex justify-between">
                <el-text size="default" type="warning">
                  <amount-text :amount="item.skuInfo.lowerPrice"></amount-text>
                </el-text>
                <el-tag v-if="item.type === 'PKG'" size="small">套餐</el-tag>
              </div>
            </div>
          </div>
        </template>
      </div>

    </el-scrollbar>
    <div v-else class="flex justify-center items-center flex-1">
      <el-empty v-if="!items.length" description="暂无商品数据!"></el-empty>
    </div>



    <div class="flex flex-row-reverse p-[12px] border-t">
      <el-pagination size="small" class="w-full flex justify-center" :background="true"
                     @size-change="handleLoadData"
                     @change="handleLoadData"
                     v-model:current-page="pager.pageIndex" v-model:page-size="pager.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="pager.itemCount" />
    </div>
  </div>


</template>

<style scoped>

</style>
