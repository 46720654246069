<script setup lang="ts">
import DcDictSelect from 'dc-comp-sdk/src/components/form/DcDictSelect.vue'
import DcSinglePicUploader from 'dc-comp-sdk/src/components/form/DcSinglePicUploader.vue'
import { getCurrentInstance, onMounted, ref } from 'vue'
import Sortable from 'sortablejs'
import { v4 as uuidv4 } from 'uuid'

const table = ref()
const modelValue = defineModel<Array<any>>()

function handleDelete(row: any) {
  modelValue.value?.splice(modelValue.value.indexOf(row), 1)
}

function handleAdd() {
  modelValue.value?.push({
    title: '', pic: '', sortNum: 0, jumpData: '',$id:uuidv4()
  })
}

function handleInitSortable() {
  //VUE3中强制更新
  let { ctx } = getCurrentInstance();
  if (!table.value || !table.value['$el']) {
    return
  }
  let tbody = table.value['$el'].querySelectorAll('tbody')[0]

  if (!tbody) {
    return
  }

  Sortable.create(tbody as HTMLElement, {
    animation: 150,
    ghostClass: 'blue-background-class',
    handle: '.sort-btn',
    onEnd: function(evt: any) {
      const movedItem = modelValue.value?.splice(evt.oldIndex, 1)[0]
      if (movedItem) {
        modelValue.value?.splice(evt.newIndex, 0, movedItem)
        // Update sortNum after reordering
        modelValue.value?.forEach((item, index) => {
          item.sortNum = index
        })
        console.log(modelValue.value)
        ctx.$forceUpdate();

        table.value.doLayout();
      }
    }
  })
}

onMounted(() => {
  handleInitSortable()
})

</script>

<template>
  <div class="w-full">
    <el-table ref="table" :data="modelValue" :row-key="(row:any)=>{return row.id ? row.id : row.$id}" border>
      <el-table-column label="图片" width="158px" align="center" prop="pic">
        <template #default="scope">
          <DcSinglePicUploader width="128px" height="128px" v-model="scope.row.pic"></DcSinglePicUploader>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title">
        <template #default="scope">
          <el-input v-model="scope.row.title" placeholder="请输入图片标题"></el-input>
        </template>
      </el-table-column>

      <el-table-column label="跳转配置" prop="jumpData">
        <template #default="scope">
          <el-input v-model="scope.row.jumpData" placeholder="跳转配置"></el-input>
        </template>
      </el-table-column>

      <el-table-column label="跳转配置" prop="sortNum">
        <template #default="scope">
          <el-input v-model="scope.row.sortNum" disabled></el-input>
        </template>
      </el-table-column>

      <el-table-column width="120px" label="操作" align="right">
        <template #default="scope">
          <el-link :underline="false" class="mr-[12px]" @click="handleDelete(scope.row)" type="danger">删除</el-link>
          <el-link :underline="false" class="sort-btn" type="info">排序</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-[16px]">
      <el-button size="default" @click="handleAdd">新增</el-button>
    </div>
  </div>
</template>

<style scoped>
.blue-background-class {
  background-color: blue !important;
}
</style>
