<script setup lang="ts">
import { onMounted, toRef } from 'vue'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
const props = defineProps(['data','printTime','tpl','logo'])
const data = toRef(props.data)
const printTime = toRef(props.printTime)
function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}
</script>

<template>
  <div :class="tpl">
    <div id="shp-delivery-order-pint-tpl" class="shp-delivery-order-pint-tpl p-[20px] bg-[#ffffff]">
      <div class="flex justify-between">
        <DcSinglePicShow width="150px" height="150px" :model-value="logo"></DcSinglePicShow>
        <div class="time-info flex-1 flex items-end flex-col">
          <div class="time-info-item !mb-[24px]" style="font-size: 32px !important;color: #bd2c00 !important;">{{ data.number }}</div>
          <div class="time-info-item">
            送貨日期：
            <DcDateFormat v-model="data.shipDate" format="YYYY-MM-DD HH:mm"></DcDateFormat>
          </div>
<!--          <div class="time-info-item">-->
<!--            打印日期：<DcDateFormat v-model="printTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>-->
<!--          </div>-->
        </div>
      </div>

      <div class="h-[2px] h-line mt-[14px]" ></div>

      <div class="mt-[20px]">
        <div class="card-title">收貨人信息</div>
        <table class="w-full">
          <tr>
            <td class="td-hd r-label">會員級別</td>
            <td class="l-value">銅會員</td>
            <td class="td-hd r-label">收貨人姓名</td>
            <td class="l-value">{{data.recipient}}</td>
          </tr>
          <tr>
            <td class="td-hd r-label">聯絡電話</td>
            <td class="l-value">{{data.recipientPhone}}</td>
            <td class="td-hd r-label">中文送貨地址</td>
            <td class="l-value">{{data.zone}},{{data.recipientAddress}}</td>
          </tr>
          <tr>
            <td class="td-hd r-label">訂單備注</td>
            <td class="l-value" colspan="3">{{data.remarks ? data.remarks : '(空)'}}</td>
          </tr>
          <tr v-if="data.shipTimeRange">
            <td class="td-hd r-label">送貨時段</td>
            <td class="l-value" colspan="3">{{data.shipTimeRange}}</td>
          </tr>
        </table>
      </div>

      <div class="mt-[20px]">
        <div class="card-title">送貨單</div>
        <table class="w-full">
          <thead class="th-bg text-[#000000]">
          <tr>
            <td>產品名稱</td>
            <td>食品規格</td>
            <td>數量</td>
            <td>價格(HKD)</td>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item,i) in data.orderItems" :key="i">
            <template v-if="item.prodType === 'PKG'">
              <tr class="item-info">
                <td colspan="2">{{item.name}}</td>
                <td>{{item.itemQty}}</td>
                <td>{{item.subtotal.toFixed(2)}}</td>
              </tr>
              <tr>
                <td colspan="4"  style="color: #000000">套餐明細</td>
              </tr>
              <tr v-for="(pkgMustItem,index) in item.pkgMustItems" :key="index" class="pkg-item">
                <td>(必選){{pkgMustItem.name}}</td>
                <td>
                  <template v-for="(skuItem,i) in qsParse(pkgMustItem.skuCombo)" :key="i">
                      <span class="mr-[12px]">
                          <el-text size="small" style="color: #000000">
                          {{ skuItem.name }} : {{ skuItem.value }}
                      </el-text>
                      </span>
                  </template>
                  <template v-if="!qsParse(pkgMustItem.skuCombo).length">
                    <el-text size="small"  style="color: #000000">
                      主規格
                    </el-text>
                  </template>
                </td>
                <td>{{pkgMustItem.itemQty}}</td>
                <td>{{(pkgMustItem.price * pkgMustItem.itemQty).toFixed(2)}}</td>
              </tr>
              <template v-for="(pkgCustItem,index) in item.pkgCustItems" :key="index">

                <template v-for="(citem,cIndex) in pkgCustItem.items.filter(e=>e.choosed)" :key="cIndex">
                  <tr  class="pkg-item">
                    <td>(自選){{pkgCustItem.name}}-{{citem.name}}</td>
                    <td>
                      <template v-for="(skuItem,i) in qsParse(citem.skuCombo)" :key="i">
                      <span class="mr-[12px]">
                          {{ skuItem.name }} : {{ skuItem.value }}
                      </span>
                      </template>
                      <template v-if="!qsParse(citem.skuCombo).length">
                        主規格
                      </template>
                    </td>
                    <td>{{citem.itemQty}}</td>
                    <td>{{(citem.price * citem.itemQty).toFixed(2)}}</td>
                  </tr>
                </template>


              </template>

            </template>
            <template v-else>
              <tr  class="item-info">
                <td colspan="1">{{item.name}}</td>
                <td>
                  <template v-for="(skuItem,i) in qsParse(item.skuCombo)" :key="i">
                      <span class="mr-[12px]">
                          {{ skuItem.name }} : {{ skuItem.value }}
                      </span>
                  </template>
                  <template v-if="!qsParse(item.skuCombo).length">
                    主規格
                  </template>
                </td>
                <td>{{item.itemQty}}</td>
                <td>{{item.subtotal.toFixed(2)}}</td>
              </tr>
              <tr v-if="item.remarks ">
                <td colspan="4">備注:{{item.remarks ? item.remarks : '(空)'}}</td>
              </tr>
            </template>
          </template>


          </tbody>
        </table>
      </div>

      <div class="flex justify-end">
        <div class="cost-info w-[200px] mt-[40px]">
          <div class="cost-item">
            <div class="cost-item-label">產品小計</div>
            <div class="cost-item-value">$ {{data.subtotal.toFixed(2)}}</div>
          </div>
          <div class="cost-item">
            <div class="cost-item-label">手續費</div>
            <div class="cost-item-value">$ {{data.handleFee.toFixed(2)}}</div>
          </div>
          <div class="cost-item">
            <div class="cost-item-label">訂單運費</div>
            <div class="cost-item-value">$ {{data.shipFee.toFixed(2)}}</div>
          </div>
          <div class="cost-item">
            <div class="cost-item-label">會員優惠</div>
            <div class="cost-item-value">$ {{(-data.subtotal * (1-data.vipDiscount)).toFixed(2)}}</div>
          </div>
          <div class="cost-item">
            <div class="cost-item-label">其他優惠</div>
            <div class="cost-item-value">$ {{(-data.discountAmount).toFixed(2)}}</div>
          </div>
          <div class="cost-item border-t-[2px] border-b-[2px] pt-[12px] pb-[12px] sum-bd-color">
            <div class="cost-item-label">合計</div>
            <div class="cost-item-value">$ {{data.payAmount.toFixed(2)}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>

.rrs {
  $theme: rrs;
  @import '@/assets/print-tpl';
}

.jsh {
  $theme: jsh;
  @import '@/assets/print-tpl';
}

.lb {
  $theme: lb;
  @import '@/assets/print-tpl';
}

.tl {
  $theme: tl;
  @import '@/assets/print-tpl';
}

.sy {
  $theme: sy;
  @import '@/assets/print-tpl';
}

.ttt {
  $theme: ttt;
  @import '@/assets/print-tpl';
}


.l-value{
  color: #000000;
  font-size: 16px !important;
  font-weight: bold;
}


.r-value{
  color: #000000;
  font-size: 16px !important;
  font-weight: bold !important;
}

.cost-item{
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.item-info{
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 16px !important;

}

.card-title{
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.w-full{
  font-weight: bold !important;
  font-size: 16px !important;

}

#shp-delivery-order-pint-tpl{
  font-weight: bold !important;
}

.r-label{
  font-size: 16px !important;
}


tr,td,th,.time-info-item{
  font-size: 16px !important;

}

</style>
